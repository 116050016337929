export const CONNECTION_GAMES = [
  [
    {
      category: "Actions of Water",
      words: ["Jet", "Spray", "Churn", "Wave"],
      difficulty: 3,
    },
    {
      category: "Synonms of Confuse",
      words: ["Throw", "Puzzle", "Baffle", "Bewilder"],
      difficulty: 2,
    },
    {
      category: "Cuts of a Cow",
      words: ["Chuck", "Flank", "Round", "Shank"],
      difficulty: 1,
    },

    {
      category: "Words that Rhyme with the Names of Seasons",
      words: ["Fling", "Bummer", "Crawl", "Splinter"],
      difficulty: 4,
    },
  ],
];
